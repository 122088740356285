var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message &&
    _vm.message.isUnread !== undefined &&
    _vm.message.isUnread !== null
    ? _c("div", [
        _vm.message.isUnread
          ? _c("span", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "components.MessageStatus.unread",
                  expression: "'components.MessageStatus.unread'"
                }
              ],
              staticClass: "badge bg-info"
            })
          : _vm._e(),
        !_vm.message.isUnread
          ? _c("span", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "components.MessageStatus.read",
                  expression: "'components.MessageStatus.read'"
                }
              ],
              staticClass: "badge bg-success"
            })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }